import React from 'react';
import { Carousel } from 'react-bootstrap';
// import Marquee from 'react-fast-marquee';
import './CorporateSR.css'

import c1 from '../../assets/csr/csr1.jpg'
import c2 from '../../assets/csr/csr2.jpg'
import c3 from '../../assets/csr/csr3.jpg'
import c4 from '../../assets/csr/csr4.jpg'
import c5 from '../../assets/OpenDay/oxford.jpg'
import cBg from '../../assets/csr/csrBg.png'
import cBg1 from '../../assets/csr/csrBg1.png'




const CorporateCR = () => {


    return (
        <div>
            <div className='container '>
                <div className='row  '>
                    <div className='col-md-6 p-0 csrBg'>
                        <div className='text-center'>
                            <img style={{width: '57%'}} className='img-fluid ' src={cBg1} alt="" />
                        </div>
                    </div>
                    <div className='col-md-6 p-0'>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block imgCr"
                                    src={c5}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block imgCr"
                                    src={c1}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block imgCr"
                                    src={c2}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block imgCr"
                                    src={c3}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block imgCr"
                                    src={c4}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CorporateCR;